.navbar {
  height: var(--navbar-height);
  background: var(--bs-blue-800);
}

main {
  > .container-fluid {
    max-width: 1400px;
    margin: var(--navbar-height) auto 0 auto;
  }
}

.cursor-pointer { cursor: pointer; }

.aiPrompt {
  font-family: monospace;
  white-space: pre-wrap;
}

.aiResult {
  font-family: monospace;
  white-space: pre-wrap;
  color: rebeccapurple;
}

.fs-7 { font-size: 0.875rem; }
.fs-8 { font-size: 0.75rem; }

.row > div { padding:1rem !important; }

.airun-tools {
  .card {
    box-shadow: var(--bs-box-shadow);
    transform: scale(0.95);
    transition: all 150ms ease-out;

    &:hover,
    &:focus {
      box-shadow: var(--bs-box-shadow-lg);
      transform: scale(1);
    }

    img { height: 200px; }
  }
}

.cursor-alias {cursor: alias;}
.cursor-all-scroll {cursor: all-scroll;}
.cursor-auto {cursor: auto;}
.cursor-cell {cursor: cell;}
.cursor-col-resize {cursor: col-resize;}
.cursor-context-menu {cursor: context-menu;}
.cursor-copy {cursor: copy;}
.cursor-crosshair {cursor: crosshair;}
.cursor-default {cursor: default;}
.cursor-e-resize {cursor: e-resize;}
.cursor-ew-resize {cursor: ew-resize;}
.cursor-grab {cursor: grab;}
.cursor-grabbing {cursor: grabbing;}
.cursor-help {cursor: help;}
.cursor-move {cursor: move;}
.cursor-n-resize {cursor: n-resize;}
.cursor-ne-resize {cursor: ne-resize;}
.cursor-nesw-resize {cursor: nesw-resize;}
.cursor-ns-resize {cursor: ns-resize;}
.cursor-nw-resize {cursor: nw-resize;}
.cursor-nwse-resize {cursor: nwse-resize;}
.cursor-no-drop {cursor: no-drop;}
.cursor-none {cursor: none;}
.cursor-not-allowed {cursor: not-allowed;}
.cursor-pointer {cursor: pointer;}
.cursor-progress {cursor: progress;}
.cursor-row-resize {cursor: row-resize;}
.cursor-s-resize {cursor: s-resize;}
.cursor-se-resize {cursor: se-resize;}
.cursor-sw-resize {cursor: sw-resize;}
.cursor-text {cursor: text;}
.cursor-w-resize {cursor: w-resize;}
.cursor-wait {cursor: wait;}
.cursor-zoom-in {cursor: zoom-in;}
.cursor-zoom-out {cursor: zoom-out;}

.card-body > .datagrid { padding: 0; }
.card-body > .datagrid form { padding: 0; }