// Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300..800;1,300..800&display=swap');

// Icons
@import "bootstrap-icons";

// Custom colors
// @see https://getbootstrap.com/docs/5.3/customize/color-modes/#adding-theme-colors
$primary: #1964AA;
$secondary: #818FA1;
$more-colors: (
        "blue": $primary,
        "blue-700": $blue-700,
        "blue-800": $blue-800,
        "primary": $primary,
        "primary-bg-subtle": #E8EFF7,
        "primary-border-subtle": #BAD1E5
);

// Override theme colors map
$theme-colors: map-merge($theme-colors, $more-colors);

// Load all bootstrap styles
// @see https://github.com/twbs/examples/blob/main/webpack/src/scss/styles.scss
@import "bootstrap/scss/bootstrap";

:root {
  --bs-font-sans-serif: "Merriweather Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --navbar-height: 60px
}
.btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-font-family: var(--bs-font-sans-serif);
  --bs-btn-font-weight: 500;
  --bs-btn-font-size: 0.875rem;
}


a { color: $primary; }

.form-floating > .form-control:-webkit-autofill ~ label { transform: scale(0.75) translateY(-0.5rem) translateX(0.15rem); }

textarea::placeholder,
input::placeholder {
  color: #a1a1a1 !important;
}

.form-group.required label:not(.not-required):after {
  content: ' *';
  font-weight: bold;
  color: red;
}
